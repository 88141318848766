import React from "react";
import "./NotFound.css";

export default function NotFound() {
	return (
		<div className="NotFound text-center">
			<h3>Sorry, page not found!</h3>
		</div>
	);
}
